import { a } from "@react-spring/web"
import { useEffect, useState } from "react"
import { ColorPicker, useColor } from "react-color-palette"
import "react-color-palette/lib/css/styles.css"
import styled from "styled-components"
import "styled-components/macro"
import { Link } from "wouter"
import { makeid } from "./index"
import { initSignedCall } from 'clevertap-signed-call'
import toast, { Toaster } from 'react-hot-toast';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import { ACC_ID, API_KEY, FORM_PORTAL_ID, FORM_ID } from '../src/constants'

export default function HubspotApp() {
  const data = useHubspotForm({
    portalId: FORM_PORTAL_ID,
    formId: FORM_ID,
    target: "#my-hubspot-form"
  });

  return (
    <div className="HubspotApp">
      <div>
        <div id="my-hubspot-form"></div>
      </div>
    </div>
  );
}

const Container = styled(a.div)`
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`
// pointer-events: none;

const Jumbo = styled.div`
  white-space: pre;
  font-size: 2em;
  font-weight: 800;
  letter-spacing: -4px;
  @media only screen and (max-width : 620px){
    font-size: 1.2em;
  }
`

const NavRight = styled(a.div)`
  position: fixed;
  left:0px;
  width: 100%;
  height: 100%;
  diplay:flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.75em;
  @media only screen and (max-width : 1000px){
    display: block;
    top: 0px;
    position: relative;
    font-size: 1.2em;
  }
`

const Box = styled(a.div)`
  position: absolute;
  transform: translate3d(-50%, -42%, 0);
  will-change: opacity;
`

const Line = styled(a.div)`
  position: relative;
  width: 100%;
  will-change: transform;
  overflow: hidden;
  line-height: 1.5em;
  @media only screen and (max-width : 620px){
    line-height: 1.8em !important;
    overflow: visible;
  }
`

const Cover = styled(a.div)`
  position: absolute;
  will-change: background, transform;
  top: 0;
  left: 0;
  width: 137%;
  height: 120%;
`

function Nav({ location, seturl, sethexcode, receiver, url, hexcode, ...props }) {
  const [enable, setEnable] = useState(true)
  const [PatchClient, setPatchClient] = useState(null)
  const loc = location
  const [color, setColor] = useColor("hex", "#121212");
  const [showPicker, setShowPicker] = useState(false)
  const [skip, setSkip] = useState(true)
  const [context, setContext] = useState("")
  const [businesstype, setBusinessType] = useState('')
  var fooddeliverycolor = useColor("hex", "#B185A7")
  var hyperlocalcolor = useColor("hex", "#C3A29E")
  var ridehailingcolor = useColor("hex", "#5C80BC")
  var fintechcolor = useColor("hex", "#DFF8EB")
  let clevertap = window.clevertap

  const handleClick = () => {
    setShowPicker(!showPicker)
  }

  const bodyClick = () => {
    setShowPicker(showPicker)
  }

  const ecommerce = () => {
    setBusinessType('ecommerce')
    seturl('https://clevertap.com/wp-content/uploads/2024/02/ecommerce-image.png')
    setColor(hyperlocalcolor[0])
    sethexcode("#C3A29E")
  }

  const delivery = () => {
    setBusinessType('delivery')
    seturl('https://clevertap.com/wp-content/uploads/2024/02/delivery-image.png')
    setColor(fooddeliverycolor[0])
    sethexcode("#B185A7")
  }

  const ridehailing = () => {
    setBusinessType('ridehailing')
    seturl('https://clevertap.com/wp-content/uploads/2024/02/ride-hailing-image.png')
    setColor(ridehailingcolor[0])
    sethexcode("#5C80BC")
  }

  const finTech = () => {
    setBusinessType('finTech')
    seturl('https://clevertap.com/wp-content/uploads/2024/02/bfsi-image.png')
    setColor(fintechcolor[0])
    sethexcode("#DFF8EB")
  }

  setTimeout(() => {
    var fieldValid = document.getElementById("imgInputField");
    var fieldValid2 = document.getElementById("textInput");
    if(document.getElementById("registerBtn") !== null) {
      document.getElementById("registerBtn").onclick = function(values) {
        if(!fieldValid.value || !fieldValid2.value) {
          values.preventDefault();
          fieldValid.style.border = "1px solid red";
          fieldValid2.style.border = "1px solid red";
        } else {
          fieldValid.style.border = "1px solid #ddd";
          fieldValid2.style.border = "1px solid #ddd";
        }
      };
    }
  },10);

  setTimeout(() => {

    var businessChoice = document.getElementsByClassName('businesschoices');
    for(var i = 0; i < businessChoice.length; i++){
      businessChoice[i].onclick = function(){
        if(this.textContent == "E-Commerce") {
          localStorage.setItem('bType','ecommerceRecom');
          setInterval(() => {
            document.getElementById(localStorage.getItem('bType')).style.display = "block";
          }, 2000);
        } if(this.textContent == "Delivery") {
          localStorage.setItem('bType','deliveryRecom');
          setInterval(() => {
            document.getElementById(localStorage.getItem('bType')).style.display = "block";
          }, 2000);
        } if(this.textContent == "Ride Hailing") {
          localStorage.setItem('bType','rideHailingRecom');
          setInterval(() => {
            document.getElementById(localStorage.getItem('bType')).style.display = "block";
          }, 2000);
        } if(this.textContent == "BFSI") {
          localStorage.setItem('bType','bfsiRecom');
          setInterval(() => {
            document.getElementById(localStorage.getItem('bType')).style.display = "block";
          }, 2000);
        }
      };
    }

    var btnSubmit = document.querySelector("input[type='submit']");
    var checkThankyouMsg = 0;
      if (btnSubmit) {
        btnSubmit.addEventListener('click', function() {
          document.querySelector("input[name='magic_link']").value = document.getElementsByClassName("hiddenMagicLink")[0].value;
          var clearCheck=setInterval(() => {
          if (document.getElementsByClassName("submitted-message").length) {
            window.history.pushState("", "Make a call", "/enter");
          }
          if(checkThankyouMsg == 1) clearInterval(clearCheck);
          checkThankyouMsg++;

          document.getElementById('step-content-call').appendChild(
            document.getElementById('sc-sdk')
          );

        },1000);
      });
    }

    /*setInterval(() => {
      if(document.getElementById('sc__og--cancel') !== null) {
        document.getElementById('sc__og--cancel').onclick = function(){
          window.location.href="https://clevertap.com/omnichannel-engagement/signed-call/";
        }
      }
      if(document.getElementById('sc__ong--hangup--container') !== null) {
        document.getElementById('sc__ong--hangup--container').onclick = function(){
          window.location.href="https://clevertap.com/omnichannel-engagement/signed-call/";
        }
      }
    }, 5000);*/

  },1500);

  useEffect(() => {
    if(clevertap.getCleverTapID()) {
      (() => initSignedCall({
        accountId: ACC_ID,
        apikey: API_KEY,
        cuid: makeid(12),
        name: '',
        ringtone: '',
        clevertap : clevertap
      }).then(res => {
        setPatchClient(res);
      }).catch(err => {
        console.log('error', err);
      }))()
    } else {
      let interval = null;
      interval = setInterval(() => {
        if(clevertap.getCleverTapID()) {
          clearInterval(interval);
            (() => initSignedCall({
              accountId: ACC_ID,
              apikey: API_KEY,
              cuid: makeid(12),
              name: '',
              ringtone: '',
              clevertap : clevertap
        
            }).then(res => {
              setPatchClient(res);
            }).catch(err => {
              console.log('error', err);
            }))()
        }
      }, 5000);
      // return () => clearInterval(interval);
    }
  }, [])

  const call = () => {
    let callContext
    if (context) {
      callContext = context
    } else {
      callContext = "Your Order Is Here"
    }
    if (PatchClient) {
      (() => PatchClient.call(
        receiver, callContext, {
        pstn: false,
        webhook: '',
        autoFallback: true,
        cli: {
          cc: "91",
          phone: "8707616903",
        },
        var1: '',
        var2: '',
      }
      )
      .then((response) => {
        if (response) {
          console.log("Call response : ", response);
        }
      }).catch((error) => {if(error.message !== undefined){toast.error(error.message,
        {position: 'top-center',
        duration: 5000,
        style:{width:"1500px",height:"100px",background: 'rgb(245 251 239)',color: 'rgb(58 62 59)'}})}}))()
    }
  }

  useEffect(()=>{
    if(loc == '/delivery'){
      {delivery()}
    }
  },[loc])
  
  var component = null;
  var next = null;

  const radioOptions = () => {

    const defaultColor = () => {
      setSkip(true)
    }

    const updateColor = () => {
      setSkip(false)
    }

    const handleUrl = (e) => {
      if (!e.target.value) {
        seturl(e.target.value)
        setEnable(false)
        return
      }
      seturl(e.target.value)
      setEnable(true)

      var urlImg = document.getElementById("imgInputField").value;
      var image = urlImg;
      document.getElementById("imgUploadedPreview").src=image;
    }

    const handleColor = (val) => {
      setColor(val)
      sethexcode(val.hex)
    }
    var imgvalue = ''

    switch (loc) {
      case '/':
        return (
          <>
        <div className="sidebar lhs">
          <div className="lhs-content">
            <a className="backToPrev appPageLink" href="https://clevertap.com/omnichannel-engagement/signed-call/" >Back to Signed Call</a>
            <img src="https://clevertap.com/wp-content/themes/clevertap2023/assets/images/ct-logo-2.svg" alt="CleverTap" className="ct-logo" />
            <span className="titleContent">Experience <span>Signed Call<sup>TM</sup></span></span>
            <span className="desc">The Industry-First Voice based Customer Engagement Solution</span>
            <div className="cta-talk-to-us"><a target="_blank" href="https://clevertap.com/live-product-demo/">Talk to Us</a></div>
            <img src="https://clevertap.com/wp-content/uploads/2024/03/signed-call-icons-image.svg" className="signedcall-img" alt="Signed Call" />
          </div>
        </div>
          <div className="businessChoice rhs">
            <div className="pageTitle">Let's Tailor this for your Industry</div>
            <Link onClick={ecommerce} to='/ecommerce' style={{ color: "#3A3E3B" }} className='businesschoices'><img src='https://clevertap.com/wp-content/uploads/2024/02/ecommerce-image.png' />E-Commerce</Link>
            <Link onClick={delivery} to='/delivery' style={{ color: "#3A3E3B" }} className='businesschoices'><img src='https://clevertap.com/wp-content/uploads/2024/02/delivery-image.png' />Delivery</Link>
            <Link onClick={ridehailing} to='/ride' style={{ color: "#3A3E3B" }} className='businesschoices'><img src='https://clevertap.com/wp-content/uploads/2024/02/ride-hailing-image.png' />Ride Hailing</Link>
            <Link onClick={finTech} to='/finTech' style={{ color: "#3A3E3B" }} className='businesschoices'><img src='https://clevertap.com/wp-content/uploads/2024/02/bfsi-image.png' />BFSI</Link>
            </div>
          </>)
      case '/ecommerce':
        return ( 
          <>
        <div className="sidebar lhs">
          <div className="lhs-content">
            <a className="backToPrev appPageLink" href="https://clevertap.com/omnichannel-engagement/signed-call/" >Back to Signed Call</a>
            <img src="https://clevertap.com/wp-content/themes/clevertap2023/assets/images/ct-logo-2.svg" alt="CleverTap" className="ct-logo" />
            <span className="titleContent">Experience <span>Signed Call<sup>TM</sup></span></span>
            <span className="desc">The Industry-First Voice based Customer Engagement Solution</span>
            <div className="cta-talk-to-us"><a target="_blank" href="https://clevertap.com/live-product-demo/">Talk to Us</a></div>
            <img src="https://clevertap.com/wp-content/uploads/2024/03/signed-call-icons-image.svg" className="signedcall-img" alt="Signed Call" />
          </div>
        </div>
          <div className="roleChoice rhs">
          <span className="backToPrev" onClick={(e) => { history.back(); }} >Previous</span>
            <div className="pageTitle">Try it out with a Role</div>
            <Link to='/personalized' style={{ color: "#133B58" }} ><img src='https://clevertap.com/wp-content/uploads/2024/03/hand-holding-credit-card-working-laptop-1.png' />Buyer</Link>
            <Link to='/personalized' style={{ color: "#133B58" }} ><img src='https://clevertap.com/wp-content/uploads/2024/03/hands-protective-gloves-young-female-vendor-passing-paper-package-1.png' />Seller</Link>
            <Link to='/personalized' style={{ color: "#133B58" }} ><img src='https://clevertap.com/wp-content/uploads/2024/03/call-center-operator-answering-clients-company-with-tech-support-1.png' />Customer Care</Link>
            <Link to='/personalized' style={{ color: "#133B58" }} ><img src='https://clevertap.com/wp-content/uploads/2024/03/young-asian-businesswoman-manager-wearing-face-mask-warehouse-using-digital-tablet-checking-inventory-1.png' />Merchant</Link>
            </div>
          </>)
      case '/delivery':
        return (
          <>
        <div className="sidebar lhs">
          <div className="lhs-content">
            <a className="backToPrev appPageLink" href="https://clevertap.com/omnichannel-engagement/signed-call/" >Back to Signed Call</a>
            <img src="https://clevertap.com/wp-content/themes/clevertap2023/assets/images/ct-logo-2.svg" alt="CleverTap" className="ct-logo" />
            <span className="titleContent">Experience <span>Signed Call<sup>TM</sup></span></span>
            <span className="desc">The Industry-First Voice based Customer Engagement Solution</span>
            <div className="cta-talk-to-us"><a target="_blank" href="https://clevertap.com/live-product-demo/">Talk to Us</a></div>
            <img src="https://clevertap.com/wp-content/uploads/2024/03/signed-call-icons-image.svg" className="signedcall-img" alt="Signed Call" />
          </div>
        </div>
          <div className="roleChoice rhs">
          <span className="backToPrev" onClick={(e) => { history.back(); }} >Previous</span>
            <div className="pageTitle">Let's Tailor this for your Industry</div>
            <Link to='/personalized' style={{ color: "#133B58" }}><img src='https://clevertap.com/wp-content/uploads/2024/03/young-worker-smiling-while-carrying-parcel-white-background-1.png' />Delivery Boy</Link>
            <Link to='/personalized' style={{ color: "#133B58" }}><img src='https://clevertap.com/wp-content/uploads/2024/03/hands-protective-gloves-young-female-vendor-passing-paper-package-1-1.png' />End User</Link>
            <Link to='/personalized' style={{ color: "#133B58" }}><img src='https://clevertap.com/wp-content/uploads/2024/03/portrait-woman-customer-service-worker-1.png' />Customer Care</Link>
            <Link to='/personalized'style={{ color: "#133B58" }}><img src='https://clevertap.com/wp-content/uploads/2024/03/asian-couples-doing-smes-deliver-their-products-online-customers-who-order-products-via-internet-helping-sell-happily-work-from-home-online-1.png' />Merchant</Link>
            </div>
          </>)
      case '/ride':
        return (
          <>
        <div className="sidebar lhs">
          <div className="lhs-content">
            <a className="backToPrev appPageLink" href="https://clevertap.com/omnichannel-engagement/signed-call/" >Back to Signed Call</a>
            <img src="https://clevertap.com/wp-content/themes/clevertap2023/assets/images/ct-logo-2.svg" alt="CleverTap" className="ct-logo" />
            <span className="titleContent">Experience <span>Signed Call<sup>TM</sup></span></span>
            <span className="desc">The Industry-First Voice based Customer Engagement Solution</span>
            <div className="cta-talk-to-us"><a target="_blank" href="https://clevertap.com/live-product-demo/">Talk to Us</a></div>
            <img src="https://clevertap.com/wp-content/uploads/2024/03/signed-call-icons-image.svg" className="signedcall-img" alt="Signed Call" />
          </div>
        </div>
          <div className="roleChoice rhs">
          <span className="backToPrev" onClick={(e) => { history.back(); }} >Previous</span>
            <div className="pageTitle">Let's Tailor this for your Industry</div>
            <Link to='/personalized' style={{ color: "#133B58" }} ><img src='https://clevertap.com/wp-content/uploads/2024/03/Arab-driver-texting-1.png' />Driver</Link>
            <Link to='/personalized' style={{ color: "#133B58" }} ><img src='https://clevertap.com/wp-content/uploads/2024/03/image-1875.png' />End User</Link>
            <Link to='/personalized' style={{ color: "#133B58" }} ><img src='https://clevertap.com/wp-content/uploads/2024/03/call-center-operator-answering-clients-company-with-tech-support-1-1.png' />Customer Care</Link>
            
            </div>
          </>)
      case '/finTech':
        return (
          <>
        <div className="sidebar lhs">
          <div className="lhs-content">
            <a className="backToPrev appPageLink" href="https://clevertap.com/omnichannel-engagement/signed-call/" >Back to Signed Call</a>
            <img src="https://clevertap.com/wp-content/themes/clevertap2023/assets/images/ct-logo-2.svg" alt="CleverTap" className="ct-logo" />
            <span className="titleContent">Experience <span>Signed Call<sup>TM</sup></span></span>
            <span className="desc">The Industry-First Voice based Customer Engagement Solution</span>
            <div className="cta-talk-to-us"><a target="_blank" href="https://clevertap.com/live-product-demo/">Talk to Us</a></div>
            <img src="https://clevertap.com/wp-content/uploads/2024/03/signed-call-icons-image.svg" className="signedcall-img" alt="Signed Call" />
          </div>
        </div>
          <div className="roleChoice rhs">
          <span className="backToPrev" onClick={(e) => { history.back(); }} >Previous</span>
            <div className="pageTitle">Let's Tailor this for your Industry</div>
            <Link to='/personalized' style={{ color: "#133B58" }} ><img src='https://clevertap.com/wp-content/uploads/2024/03/smiley-businesswoman-posing-outdoors-with-arms-crossed-copy-space-1.png' />Bank Staff</Link>
            <Link to='/personalized' style={{ color: "#133B58" }} ><img src='https://clevertap.com/wp-content/uploads/2024/03/texting-with-friend-1.png' />End User</Link>
            <Link to='/personalized' style={{ color: "#133B58" }} ><img src='https://clevertap.com/wp-content/uploads/2024/03/businesswoman-call-center-office-2.png' />Customer Care</Link>
            <Link to='/personalized' style={{ color: "#133B58" }} ><img src='https://clevertap.com/wp-content/uploads/2024/03/beautiful-smart-asian-young-entrepreneur-business-woman-owner-sme-online-checking-product-stock-save-computer-working-home-1.png' />Merchant</Link>
            </div>
          </>)
      case '/personalized':
        switch (businesstype) {
          case 'ecommerce':
            imgvalue = 'https://clevertap.com/wp-content/uploads/2024/02/ecommerce-image.png'
            break;
          case 'delivery':
            imgvalue = 'https://clevertap.com/wp-content/uploads/2024/02/delivery-image.png'
            break;
          case 'ridehailing':
            imgvalue = 'https://clevertap.com/wp-content/uploads/2024/02/ride-hailing-image.png'
            break;
          case 'finTech':
            imgvalue = 'https://clevertap.com/wp-content/uploads/2024/02/bfsi-image.png'
            break;
          default:
            imgvalue = '';
        }

        return (
          <>
          <div className="sidebar lhs" onClick={bodyClick}>
          <div className="lhs-content">
            <a className="backToPrev appPageLink" href="https://clevertap.com/omnichannel-engagement/signed-call/" >Back to Signed Call</a>
            <img src="https://clevertap.com/wp-content/themes/clevertap2023/assets/images/ct-logo-2.svg" alt="CleverTap" className="ct-logo" />
            <span className="titleContent">Experience <span>Signed Call<sup>TM</sup></span></span>
            <span className="desc">The Industry-First Voice based Customer Engagement Solution</span>
            <div className="cta-talk-to-us"><a target="_blank" href="https://clevertap.com/live-product-demo/">Talk to Us</a></div>
            <img src="https://clevertap.com/wp-content/uploads/2024/03/signed-call-icons-image.svg" className="signedcall-img" alt="Signed Call" />
          </div>
        </div>
        <div className="roleChoice rhs" >
          <span className="backToPrev" onClick={(e) => { history.back(); }} >Previous</span>
          <div className="pageTitle">Create that In-app Calling Experience</div>
          
            <div className="parentperson">
              <div className="personalNav">
                <div className="col-md-4 imgurl" >
                  <p>Add Brand Logo</p>
                  <div className="logo-wrap">
                    {/* <form runat="server" class="uploadImg">
                      <input accept="image/*" type="file" id="imgInputField" onClick={imgInputFunc} />
                    </form> */}
                    <input className="input" id="imgInputField" placeholder="Enter logo URL" type="text" onChange={handleUrl} />
                    <img id="imgUploadedPreview" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" alt="Logo" />
                  </div>
                </div>
                <div className="col-md-4 context" >
                  <p>Give Context</p>
                  <input className="input" type="text" id="textInput" placeholder="Your Order Is Here" maxLength={30} style={{ }} onChange={(e) => setContext(e.target.value)} />
                  <p className="no-margin" style={{fontSize : "0.48em"}}>Recommended</p>
                  <ul className="recomTxtWrap" id="ecommerceRecom">
                    <li className="recomTxt" style={{fontSize : "0.48em"}}>Your order is here</li>
                    <li className="recomTxt" style={{fontSize : "0.48em"}}>We’re dispatching your order</li>
                    <li className="recomTxt" style={{fontSize : "0.48em"}}>Your order is delivered</li>
                  </ul>
                  <ul className="recomTxtWrap" id="deliveryRecom">
                    <li className="recomTxt" style={{fontSize : "0.48em"}}>Unable to locate the address</li>
                    <li className="recomTxt" style={{fontSize : "0.48em"}}>Know the ETA</li>
                    <li className="recomTxt" style={{fontSize : "0.48em"}}>Your order is delivered</li>
                  </ul>
                  <ul className="recomTxtWrap" id="rideHailingRecom">
                    <li className="recomTxt" style={{fontSize : "0.48em"}}>Share the exact location</li>
                    <li className="recomTxt" style={{fontSize : "0.48em"}}>Slight delay due to traffic</li>
                    <li className="recomTxt" style={{fontSize : "0.48em"}}>Your cab has arrived</li>
                  </ul>
                  <ul className="recomTxtWrap" id="bfsiRecom">
                    <li className="recomTxt" style={{fontSize : "0.48em"}}>KYC Verification</li>
                    <li className="recomTxt" style={{fontSize : "0.48em"}}>Confirm account details</li>
                    <li className="recomTxt" style={{fontSize : "0.48em"}}>Regarding your loan application </li>
                  </ul>
                </div>
                <div className="col-md-4 hexchild" >
                  <p>Choose the Theme</p>
                  <div className="coverD">
                    <div
                      className="d-block"
                      onClick={handleClick}
                    >
                      <div className="colorbox" style={{ background: color.hex }} />
                    </div>
                    {showPicker ?
                      <div className="popover">
                        <ColorPicker width={240} height={100} color={color} onChange={handleColor} hideHSV hideRGB dark />
                      </div> : null}
                  </div>
                  <br />
                  {/* { <Link onClick={updateColor} to='/enter'><button className="c-btn enter custom-flex-btn" disabled={!enable} >Enter</button></Link> } */}
                </div>
              </div>
              <div className="buttonNav" id="registerBtn">
                <Link onClick={updateColor} to='/register'><button className="col-md-6 enter btn c-btn" disabled={!enable} >Enter</button></Link>
              </div>
            </div>
          </div>
          </>
        )
      case '/skip':
      case '/register':
        return (
          <>
        <div className="sidebar lhs">
          <div className="lhs-content">
            <a className="backToPrev appPageLink" href="https://clevertap.com/omnichannel-engagement/signed-call/" >Back to Signed Call</a>
            <img src="https://clevertap.com/wp-content/themes/clevertap2023/assets/images/ct-logo-2.svg" alt="CleverTap" className="ct-logo" />
            <span className="titleContent">Experience <span>Signed Call<sup>TM</sup></span></span>
            <span className="desc">The Industry-First Voice based Customer Engagement Solution</span>
            <div className="cta-talk-to-us"><a target="_blank" href="https://clevertap.com/live-product-demo/">Talk to Us</a></div>
            <img src="https://clevertap.com/wp-content/uploads/2024/03/signed-call-icons-image.svg" className="signedcall-img" alt="Signed Call" />
          </div>
        </div>
          <div className="roleChoice rhs registerForm">
          <span className="backToPrev" onClick={(e) => { history.back(); }} >Previous</span>
            <div className="pageTitle">Superb! You’re almost there...</div>
            <p className="inputLabel">Add your work email to experience Signed Call™</p>

            <HubspotProvider>
                <HubspotApp />
            </HubspotProvider>

            </div>
          </>)
      case '/enter':
        return (
          <>
          <div className="sidebar lhs">
            <div className="lhs-content">
              <a className="backToPrev appPageLink" href="https://clevertap.com/omnichannel-engagement/signed-call/" >Back to Signed Call</a>
              <img src="https://clevertap.com/wp-content/themes/clevertap2023/assets/images/ct-logo-2.svg" alt="CleverTap" className="ct-logo" />
              <span className="titleContent">Experience <span>Signed Call<sup>TM</sup></span></span>
              <span className="desc">The Industry-First Voice based Customer Engagement Solution</span>
              <div className="cta-talk-to-us"><a target="_blank" href="https://clevertap.com/live-product-demo/">Talk to Us</a></div>
              <img src="https://clevertap.com/wp-content/uploads/2024/03/signed-call-icons-image.svg" className="signedcall-img" alt="Signed Call" />
            </div>
          </div>
          <div className="roleChoice rhs rhsSteps">
            <a href="/" className="backToPrev startOver">Start over</a>
            <div className="pageTitle">To experience Signed Call™<br/> as a Call Receiver</div>
            
            <div className="stepCall">
              <div className="step-1 steps">
                <div className="step-image"><img src="https://clevertap.com/wp-content/uploads/2024/04/step1-image.svg" alt="step 1" /></div>
                <div className="step-content">
                  <p className="step-label">Step 1</p>
                  <p className="step-title">Click on the link received on your email ID.</p>
                  <p className="step-desc">You will receive a link from signedcall@mail.clevertap.com. Best experienced on mobile.</p>
                </div>
              </div>
              <div className="step-2 steps">
                <div className="step-image"><img src="https://clevertap.com/wp-content/uploads/2024/04/step2-image.svg" alt="step 2" /></div>
                <div className="step-content" id="step-content-call">
                  <p className="step-label">Step 2</p>
                  <p className="step-title">Initiate a call</p>
                  {PatchClient ? <span className="cta-talk-to-us cta-call-wrapper"><a style={{ "cursor": "pointer" }} onClick={call}>Make Call</a></span> : null}
                </div>
              </div>
            </div>
          </div>
          </>
        )
      default:
        component = "Default one"
        next = '/'

    }
  }

  return (
    <>
    <Toaster/>
    {/* <ToastContainer
    z-Index = {"5"}
    position ={"top-right"}
    /> */}
      <NavRight className={loc === '/enter' ? 'enterNav' : ''} {...props}>
        {radioOptions()}
      </NavRight>
    </>
  )
}

export { Container, Jumbo, Nav, Box, Line, Cover }